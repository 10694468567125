import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Copyright from "./Copyright";

export default function TermsAndCondition() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Header />
      <div className="privacyPolicy">
        <h1>Terms of Use</h1> <br />
        <h4>Last updated April 10, 2023</h4>
        <div style={{ textAlign: "justify" }}>
          <p>
            These Terms apply to all your activities on the ebidhya website
            (www.ebidhya.com), (hereinafter referred to as Ebidhya or
            ebidhya.com) the ebidhya mobile applications, or any other
            application is owned by E.Bidhya education Pvt. Ltd. <br />
            <br /> Please read the following Terms and Conditions ("Terms Of
            Use") carefully. By registering for, accessing, browsing, or using
            the website and platform, you acknowledge that you have read,
            understood, and agree to be bound by the following terms and
            conditions, including the ebidhya Privacy Policy and any additional
            guidelines (as defined below) (collectively, the "terms") <br />
            <br /> As the original purchaser of content sold on the ebidhya.com,
            you are entitled to access and use the content which is identified
            in the course and which is on the ebidhya.com ("Website"). In order
            to access and use this content, you must register with ebidhya.com
            and create a password to use in accessing the content on the
            Website. Your password is unique and exclusive to you, and you may
            not transfer your password to any other person or allow any other
            person to use your password to access or use content on the Website.{" "}
            <br />
            <br /> You agree to notify ebidhya immediately if any other person
            makes unauthorized use of your password. ebidhya reserves the right
            to suspend or revoke your password in the event of any misuse of
            your password or any use of your password in violation of these
            Terms and Conditions. In the event of any such suspension or
            revocation, you will not be entitled to any refund or payment.{" "}
            <br /> <br /> These Terms of Use govern your access to and use of
            the Website and the content on the Website. By accessing and using
            the Website, you agree to these Terms of Use. If you do not agree to
            any of these Terms of Use, you may not access or use the site.
            Ebidhya reserves the right to modify these Terms of Use at any time
            and in its sole discretion. Your use of the site following any
            modification will constitute your assent to and acceptance of the
            modifications. <br />
            <br /> Upon registration, Ebidhya grants you a non-exclusive,
            non-transferable, non-assignable, personal license to access and use
            the ebidhya.com content identified in the content you purchased via
            an online/offline reader. <br />
            <br /> Ebidhya will not be liable for any delay or interruption in
            your access to the site or any content located on the site, or for
            any transmission errors, equipment or software incompatibilities,
            force majeure or other failure of performance. ebidhya.com will use
            reasonable efforts to correct any failure of performance, but
            Ebidhya will not be required to make any changes to any equipment or
            software used by Ebidhya or its contractors or agents to ensure
            compatibility with any equipment or software used by you. You may
            not use the site or the content on the site for any commercial
            purpose, including but not limited to the use of any of the content
            to market or sell goods or services to any person. You agree not to
            launch any automated system, including without limitation, "robots,"
            "spiders," or "offline readers," to access the site. <br />
            <br /> Ebidhya reserves the right to change, suspend access to, or
            remove any or all of the content on the Website at any time, for any
            reason, in its sole discretion. Ebidhya also reserves the right to
            discontinue the Website at any time, either temporarily or
            permanently. In the event of the removal of any content from the
            Website or the termination of the Website, you will not be entitled
            to any refund or payment. <br />
            <br /> You agree that your use of the site shall be at your sole
            risk, and ebidhya.com will not be held liable in any way for your
            use of the site or for any information contained on the site. All
            content contained in or referred to on the site is provided "as is,"
            without any representations or warranties, express or implied. To
            the fullest extent permitted by law, ebidhya.com disclaims all
            warranties, express or implied, including, without limitation, all
            implied warranties of merchantability and fitness for a particular
            purpose. Ebidhya makes no warranties that the site will be
            error-free, or that any errors will be corrected, or that the site
            or the server from which the site is operated will be free of
            viruses or other potentially harmful codes. Under no circumstances,
            including negligence, shall Ebidhya be held liable for any direct,
            indirect, incidental, special or consequential damages and expenses
            of any kind (including, without limitation, personal injury or
            property damage, lost profits, and damages arising from computer
            viruses, business interruption, lost data, unauthorized access to or
            use of site servers or any personal information stored therein, or
            any interruption or cessation of operation of the site) arising out
            of or in any way connected with the use of the site or any
            information contained on the site, whether such damages are based on
            contract, tort, strict liability or otherwise, even if advised of
            the possibility of such damages. <br />
            <br />
            You agree to indemnify, hold harmless and defend Ebidhya from and
            against any and all claims, damages, losses, liabilities, judgments,
            awards, settlements, costs and expenses (including attorney's fees
            and court costs) arising out of or resulting from your use of this
            Website or the violation by you of any of these Terms of Use. <br />
            <br /> You agree that any cause of action arising out of or related
            to this or your use of this site must commence within one (1) Year
            after the cause of action accrues, and will thereafter be
            permanently barred. <br />
            <br />
            These Terms of Use constitute the entire agreement between you and
            ebidhya.com concerning your use of the Website and the contents of
            the Website. If any provision is deemed invalid by a court of
            competent jurisdiction, the remaining provisions shall remain in
            full force and effect. No waiver of any the Terms of Use shall be
            deemed a further or continuing waiver of such term or condition or
            any other term or condition, and any failure by ebidhya.com to
            assert any right or provision under these Terms of Use shall not
            constitute a waiver of such right or provision. <br />
            <br />
          </p>
        </div>
      </div>
      <Footer />
      <Copyright />
    </div>
  );
}
