import React from "react";
import Header from "./Header";
import "./style1.css";
import Footer from "./Footer";
import Copyright from "./Copyright";

export default function PrivacyPolicy() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Header />
      <div className="privacyPolicy">
        <h1>Privacy Policy</h1> <br />
        <h4>Last updated April 10, 2023</h4>
        <p>
          <div style={{ textAlign: "justify" }}>
            <p>
              We at E.Bidhya Education Pvt.Ltd. (Owner of ebidhya.com or ebidhya
              or we or us) respect your privacy and want you to understand how
              we collect, use, and share data about you. This Privacy Policy
              covers our data collection practices and describes your rights to
              access, correct, or restrict our use of your personal data.
            </p>
            <p>
              Unless we link to a different policy or state otherwise, this
              Privacy Policy applies when you visit or use the ebidhya websites,
              mobile applications or related services (the{" "}
              <strong>“Services”</strong>).
            </p>
            <p>
              <strong>
                By using the Services, you agree to the terms of this Privacy
                Policy.
              </strong>{" "}
              You shouldn’t use the Services if you don’t agree with this
              Privacy Policy or any other agreement that governs your use of the
              Services.
            </p>
            <p>
              <strong>Table Of Contents</strong>
            </p>
            <ol>
              <li>What Data We Get</li>
              <li>What We Use Your Data For</li>
              <li>Who We Share Your Data With</li>
              <li>Security</li>
              <li>Your Rights</li>
              <li>Updates &amp; Contact Info</li>
            </ol>
            <p>
              <strong>1. What Data We Get</strong>
            </p>
            <p>
              We collect certain data from you directly, like information you
              enter yourself, data about your participation in courses, and data
              from third-party platforms you connect with ebidhya.
            </p>
            <p>
              <strong>1.1 Data You Provide To Us</strong>
            </p>
            <p>
              We may collect different data from or about you depending on how
              you use the Services. Below are some examples to help you better
              understand the data we collect.
            </p>
            <p>
              When you create an account and use the Services, including through
              a third-party platform, we collect any data you provide directly,
              including:
            </p>
            <p>
              <strong>Account Data</strong>
              <span style={{ marginLeft: "20px" }}>
                In order to use certain features (like enrolling in a course),
                you need to create a user account. When you create or update
                your account, we collect and store the data you provide, like
                your full name, email address, password, phone number, and
                account settings and assign you a unique identifying number
                <strong>(“Account Data”).</strong>
              </span>
            </p>
            <p>
              <strong>Profile Data</strong>
              <span style={{ marginLeft: "20px" }}>
                You can also choose to provide profile information like a photo,
                website link, social media profiles, country, or other data.
                Your Profile Data can only be viewed by you.
              </span>
            </p>
            <p>
              <strong>Payment Data</strong>
              <span style={{ marginLeft: "20px" }}>
                If you make purchases, we collect certain data about your
                purchase (such as your transaction id, token) as necessary to
                process your order. You must provide certain payment and billing
                data directly to our payment processing partners, including your
                name, credit card information. For security, E.Bidhya does not
                collect or store sensitive cardholder data, such as full credit
                card numbers or card authentication data.
              </span>
            </p>
            <p>
              When you access the Services (including browsing content), we
              collect certain data by automated means, including:
            </p>
            <p>
              <strong>System Data</strong>
              <span style={{ marginLeft: "20px" }}>
                Technical data about your computer or device, like your IP
                address, device type, operating system type and version, unique
                device identifiers, browser, browser language, domain and other
                systems data, and platform types (“System Data”).
              </span>
            </p>
            <p>
              <strong>Usage Data</strong>
              <span style={{ marginLeft: "20px" }}>
                Usage statistics about your interactions with the Services,
                including content accessed, time spent on pages or the Service,
                pages visited, features used, your search queries, click data,
                date and time, referrer, and other data regarding your use of
                the Services (“Usage Data”).
              </span>
            </p>
            <p>
              <strong>2. What We Use Your Data For</strong>
            </p>
            <p>
              We use your data to do things like provide our Services,
              communicate with you, troubleshoot issues, secure against fraud
              and abuse, improve and update our Services, analyze how people use
              our Services, serve personalized advertising, and as required by
              law or necessary for safety and integrity.
            </p>
            <p>
              We use the data we collect through your use of the Services to:
            </p>
            <ul>
              <li>
                Provide and administer the Services, including to facilitate
                participation in educational content, issue completion
                certificates, display customized content, and facilitate
                communication with other users;
              </li>
              <li>Process payments to instructors and other third parties;</li>
              <li>
                Process your requests and orders for educational content,
                products, specific services, information, or features;
              </li>
              <li>
                Communicate with you about your account by:{" "}
                <ul>
                  <li>Responding to your questions and concerns;</li>
                  <li>
                    Sending you administrative messages and information,
                    including messages from instructors, students, and teaching
                    assistants; notifications about changes to our Service; and
                    updates to our agreements;
                  </li>
                  <li>
                    Sending you information, such as by email or text messages,
                    about your progress in courses and related content, rewards
                    programs, new services, new features, promotions,
                    newsletters, and other available instructor-created content
                    (which you can opt out of at any time);
                  </li>
                  <li>
                    Sending push notifications to your wireless device to
                    provide updates and other relevant messages (which you can
                    manage from the “options” or “settings” page of the mobile
                    app);
                  </li>
                </ul>
              </li>
              <li>Manage your account and account preferences;</li>
              <li>
                Facilitate the Services’ technical functioning, including
                troubleshooting and resolving issues, securing the Services, and
                preventing fraud and abuse;
              </li>
              <li>Solicit feedback from users;</li>
              <li>Market products, services, surveys, and promotions;</li>
              <li>Market Subscription Plans to prospective customers;</li>
              <li>
                Learn more about you by linking your data with additional data
                through third-party data providers and/or analyzing the data
                with the help of analytics service providers;
              </li>
              <li>Identify unique users across devices;</li>
              <li>Tailor advertisements across devices;</li>
              <li>
                Improve our Services and develop new products, services, and
                features;
              </li>
              <li>
                Analyze trends and traffic, track purchases, and track usage
                data;
              </li>
              <li>
                Advertise the Services on third-party websites and applications;
              </li>
              <li>As required or permitted by law; or</li>
              <li>
                As we, in our sole discretion, otherwise determine to be
                necessary to ensure the safety or integrity of our users,
                employees, third parties, the public, or our Services.
              </li>
            </ul>
            <p>
              <strong>3. Who We Share Your Data With</strong>
            </p>
            <p>
              We share certain data about you with instructors, other students,
              companies performing services for us, our business partners,
              analytics and data enrichment providers, your social media
              providers, companies helping us run promotions and surveys, and
              advertising companies who help us promote our Services. We may
              also share your data as needed for security, legal compliance, or
              as part of a corporate restructuring. Lastly, we can share data in
              other ways if it is aggregated or de-identified or if we get your
              consent.
            </p>
            <p>
              <strong>4. Security</strong>
            </p>
            <p>
              We use appropriate security based on the type and sensitivity of
              data being stored. As with any internet-enabled system, there is
              always a risk of unauthorized access, so it’s important to protect
              your password and to contact us if you suspect any unauthorized
              access to your account.
            </p>
            <p>
              ebidhya takes appropriate security measures to protect against
              unauthorized access, alteration, disclosure, or destruction of
              your personal data that we collect and store. These measures vary
              based on the type and sensitivity of the data. Unfortunately,
              however, no system can be 100% secured, so we cannot guarantee
              that communications between you and ebidhya, the Services, or any
              information provided to us in connection with the data we collect
              through the Services will be free from unauthorized access by
              third parties. Your password is an important part of our security
              system, and it is your responsibility to protect it. You should
              not share your password with any third party, and if you believe
              your password or account has been compromised, you should change
              it immediately and contact ebidhya.com with any concerns.
            </p>
            <p>
              <strong>5. Your Rights</strong>
            </p>
            <p>
              You have certain rights around the use of your data, including the
              ability to opt out of promotional emails, cookies, and collection
              of your data by certain third parties. You can update or terminate
              your account from within our Services, and can also contact us for
              individual rights requests about your personal data. Parents who
              believe we've unintentionally collected personal data about their
              underage child should contact us for help deleting that
              information.
            </p>
            <p>
              <strong>6. Updates &amp; Contact Info</strong>
              <p>
                When we make a material change to this policy, we'll notify
                users via email, in- product notice, or another mechanism
                required by law. Changes become effective the day they're
                posted. Please contact us via email with any questions,
                concerns, or disputes.
              </p>
            </p>
            <p>6.1 Modifications to This Privacy Policy</p>
            <p>
              From time to time, we may update this Privacy Policy. If we make
              any material change to it, we will notify you via email, through a
              notification posted on the site, or as required by applicable law.
              We will also include a summary of the key changes. Unless stated
              otherwise, modifications will become effective on the day they are
              posted.
            </p>
            <p>6.2 Questions</p>
            <p style={{ marginBottom: "50px" }}>
              If you have any questions, concerns, or disputes regarding our
              Privacy Policy, please feel free to contact our team at&nbsp;
              <a href="mailto:ebidhyaeducation@gmail.com">
                ebidhyaeducation@gmail.com
              </a>
              , Baneshwor, Kathmandu. Contact no&nbsp;
              <b>9862170046</b>, <b>9813522418</b>
            </p>
          </div>
        </p>
      </div>
      <Footer />
      <Copyright />
    </div>
  );
}
