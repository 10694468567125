import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home1 from "./MyComponents/Home1";
import PrivacyPolicy from "./MyComponents/PrivacyPolicy";
import ChildData from "./MyComponents/ChildData";
import TermsAndCondition from "./MyComponents/TermsAndCondition";
import ContactUs from "./MyComponents/ContactUs";
import About from "./MyComponents/About";

function App() {
  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home1 />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/sub-category-list" element={<ChildData />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
