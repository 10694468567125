import React from "react";
import "./style.css";
import "./style1.css";

export default function GetStarted() {
  return (
    <div>
      <div className="bgImage">
        <img src="img/circle.png" alt="" />
      </div>
      <div className="getStarted">
        <div className="getStartedText">
          <h1>
            तयारी,<span> अब </span>
            <br /> घरै <span> बसी </span> बसी...
          </h1>
          <p>
            Provides you with the latest online learning system and material
            that help your knowledge growing.
          </p>

          <div className="appsImage">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://play.google.com/store/apps/details?id=com.avyaas.ebidhya"
            >
              <img src="img/playappstore.png" alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://play.google.com/store/apps/details?id=com.avyaas.ebidhya"
            >
              <img src="img/apple.png" alt="" />
            </a>
          </div>
        </div>
        <div className="getStartedImg">
          <img src="img/girl.png" alt="" />
        </div>
      </div>
    </div>
  );
}
