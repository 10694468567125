import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as ReactLink } from "react-router-dom";
import "./style.css";
import "./style1.css";

export default function Header() {
  const navigation = [
    {
      id: "1",
      title: "ABOUT",
      link: "/about-us",
    },
    {
      id: "2",
      title: "CONTACT",
      link: "/contact-us",
    },
  ];
  const [mobileNavigation, setMobileNavigation] = useState(false);
  return (
    <>
      {mobileNavigation && (
        <div className="MobileNavigationBar">
          <div className="MobileNavigationBarheader">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#143F91",
              }}
              className="MobileNavigationBarheaderImg">
              <img src="img/logo.webp" alt="" />
              <h3>ebidhya</h3>
            </div>

            <i
              className="fa-solid fa-xmark"
              onClick={() => setMobileNavigation(false)}></i>
          </div>

          {navigation.map((items) => (
            <Link
              to={items.link}
              key={items.id}
              smooth={true}
              duration={500}
              className="navigate2">
              <p>{items.title}</p>
            </Link>
          ))}
        </div>
      )}

      <div className=" header2">
        <ReactLink
          to="/"
          className="logoheader"
          style={{ textDecoration: "none" }}>
          {" "}
          <img className="logoebidhya" src="img/logo.webp" alt="" />
          <h3>ebidhya</h3>
        </ReactLink>
        <div className="input">
          <img src="img/search.png" alt="" />
          <input type="text" placeholder="Want to learn?" />
        </div>

        <div className="navigation">
          <Link to="courses" className="navigate">
            <p>COURSE</p>
          </Link>
          {navigation.map((items) => (
            <ReactLink to={items.link} key={items.id} className="navigate">
              <p>{items.title}</p>
            </ReactLink>
          ))}
        </div>
        <div className="leftheader">
          <ReactLink to="https://app.ebidhya.com/login">
            {" "}
            <button>GET STARTED</button>
          </ReactLink>
          <div className="Lines" onClick={() => setMobileNavigation(true)}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </>
  );
}
