import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: state.isFocused ? "1px solid #143f91" : "1px solid #989e9a",
    "&:hover": {
      border: "1px solid #143f91",
    },
    fontSize: 12,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#000",
    fontSize: 12,
    opacity: 0.5,
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
};

export default function CustomSelect(props) {
  const {
    name,
    options,
    value,
    handleChange,
    disabled,
    placeholder,
    defaultValue,
    ...rest
  } = props;

  return (
    <>
      <Select
        id={name}
        {...rest}
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={(e, data) => handleChange(e, data)}
        isDisabled={disabled}
        styles={customStyles}
        placeholder={placeholder}
        isClearable
        isSearchable
      />
    </>
  );
}
