import React from "react";
import "./style1.css";
import Header from "./Header";
import Footer from "./Footer";
import Copyright from "./Copyright";

export default function About() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Header/>
      <div className="aboutus">
        <h1>About Us</h1> <br />
        <p>
          <div style={{ textAlign: "justify" }}>
            Welcome to Nepal's Best learning platform and thank you for your
            interest in E.bidhya education Pvt. Ltd., a company incorporated
            under the Companies Act, 2063 (hereinafter referred to as Ebidhya or
            ebidhya.com) Ebidhya is a trusted platform for educational and
            learning resources founded by a group of top-notch professionals in
            Nepal. It is an online learning platform under the ownership of
            E.Bidhya Education Pvt. Ltd which is striving to help students learn
            effectively in an engaging virtual atmosphere and aims to transform
            traditional learning systems with newer approaches and to break the
            barriers of cost, location, and inaccessibility to all the learning
            spirits. Ebidhya is reimagining the possibilities of educational
            deliverance providing the highest-quality, stackable learning
            experiences with top-notch teachers in all the subjects it offers.
            It is a Shangri-La to all those hungry souls craving for knowledge
            and information and is an ultimate learning package for amateurs to
            learn about job markets, prospects in other fields, or those seeking
            promotions, professional skills or exploring new interests. Fuel
            your passion with our learning meal. We hope to cater to your needs
            for all kinds of educational resources. Come with us. Take your seat
            and craft a unique learning experience to get anywhere where you
            want to go. To increase access to high-quality researched online
            content at your fingertips from wherever you want to learn.
          </div>
        </p>
      </div>
      <Footer/>
      <Copyright />
    </div>
  );
}
