import React from "react";
import "./style1.css";

export default function Copyright() {
  return (
    <div className="copyright">
      <p>
        @2023 Copyright ebidhya Designed By{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "white" }}
          href="https://ayata.com.np/"
        >
          Ayata Inc.
        </a>{" "}
      </p>
    </div>
  );
}
