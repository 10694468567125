import React from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "./style1.css";
import fallback from "../defaultCourse.png";

export default function ChildData({ fallBackSrc = fallback }) {
  const location = useLocation();
  const childdata = location.state.courses;
  const title = location.state.title;
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <div className="category-courses">
        <h2 className="headercatagory">Courses For {title} </h2>
        <div
          className={`${
            childdata?.length <= 2
              ? "course-flex-container"
              : "course-grid-container"
          }`}>
          {childdata &&
            childdata.map((items) => (
              <div key={items.id} className="childdataone">
                <Link
                  to="https://app.ebidhya.com/login"
                  style={{ textDecoration: "none" }}>
                  <img
                    src={`http://backend.beta.ebidhya.com/images/courses/${items.src} `}
                    onError={(e) => (e.currentTarget.src = fallBackSrc)}
                    alt=""
                  />
                  <p>{items.module_id}</p>
                  <h2>{items.name}</h2>
                </Link>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
