import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style1.css";
import fallback from "../defaultCourse.png";
const courseApi = `${process.env.REACT_APP_BASE_URL}/home/course/`;
const imageApi = `${process.env.REACT_APP_BASE_URL}/images/courses`;
export default function Services({ fallBackSrc = fallback }) {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(courseApi)
      .then((res) => {
        console.log(res, "res");
        return res.json();
      })
      .then((resp) => {
        console.log(resp, "rsp");
        setData(resp?.courseGroups);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  return (
    <div className="services" id="courses">
      <p>Top Categories</p>
      <h1>
        Explore the best categories you can study <br /> from the following!
      </h1>
      <div className="courses">
        {data &&
          data?.map((items, i) => {
            const images = [];
            items.courses.map((item) => images.push(item.src));
            const randomImg = images[Math.floor(Math.random() * images.length)];
            return (
              <div
                key={items.id}
                className="popularclassesone2 popularclassesone">
                <Link
                  state={{ courses: data[i]?.courses, title: data[i]?.name }}
                  to="/sub-category-list"
                  style={{ textDecoration: "none" }}>
                  <img
                    className="topicimage"
                    src={`${imageApi}/${randomImg} `}
                    onError={(e) => (e.currentTarget.src = fallBackSrc)}
                    alt=""
                  />
                  <p style={{ textDecoration: "none" }}>Catagory</p>
                  <h1 style={{ textDecoration: "none" }}>{items.name}</h1>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}
