import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import fallback from "../defaultCourse.png";
import CustomSelect from "../components/CustomSelect";

const courseApi = `${process.env.REACT_APP_BASE_URL}/course-list/list`;
const instructorApi = `${process.env.REACT_APP_BASE_URL}/teacher/list`;
const instructorImageApi = `${process.env.REACT_APP_BASE_URL}/images/instructor`;

export default function Tutors({ fallBackSrc = fallback }) {
  const maleImg = "img/male.png";
  const femaleImg = "img/female.png";
  const [instructorData, setInstructorData] = useState([]);
  const [activeModule, setActiveModule] = useState({
    label: "",
    value: "",
  });
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    fetch(instructorApi)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        if (resp?.message) {
          setInstructorData(resp?.message);
        }
      })
      .catch((err) => {
        alert(err?.message);
      });
  }, []);

  useEffect(() => {
    fetch(courseApi)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setCourseList(resp?.data);
        setActiveModule({
          label: resp?.data?.[0]?.name,
          value: resp?.data?.[0]?.module_id,
        });
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  const courseOptions = courseList
    ?.map((item) => {
      return {
        label: item?.name,
        value: item?.module_id,
      };
    })
    .flat(1);

  const instructorListByModuleId = instructorData?.filter(
    (item) => item?.subject?.module_id === activeModule?.value
  );

  return (
    <Element className="services" id="about">
      <p>Tutors</p>
      <h1>Meet Our Tutors</h1>
      <h5>
        On Ebidhya, instructors from all over Nepal instruct thousand of
        students. We <br /> offer the knowledge and abilities.
      </h5>
      <div className="course-select-field">
        <CustomSelect
          name="teachers"
          options={courseOptions}
          handleChange={(instructorData) => {
            setActiveModule(instructorData);
          }}
          placeholder="Select Course"
          value={activeModule}
        />
      </div>
      <div className="allTuitors">
        {instructorListByModuleId?.length === 0 ? (
          <div className="noInstructor">No Instructor</div>
        ) : (
          instructorListByModuleId?.map((item) => {
            const actualImg = `${instructorImageApi}/${item.image_id}`;
            return (
              <div className="tuitorDesc" key={item.id}>
                <img
                  src={`${
                    actualImg
                      ? actualImg
                      : actualImg == null && item.gender === "M"
                      ? maleImg
                      : femaleImg
                  }`}
                  onError={(e) => (e.currentTarget.src = fallBackSrc)}
                  alt=""
                />
                <h4>
                  {item.first_name} {item.middle_name} {item.last_name}
                </h4>
                <h3>{item.qualification}</h3>
                <h3>{item.subject.subject_name}</h3>
              </div>
            );
          })
        )}
      </div>
    </Element>
  );
}
