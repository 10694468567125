import React from "react";
import "./style1.css";
import Header from "./Header";
import Footer from "./Footer";
import Copyright from "./Copyright";

export default function ContactUs() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Header />
      <h1 style={{ textAlign: "center", marginTop: "26px" }}>Contact Us</h1>
      <div className="contactus">
        <div className="innerContactus">
          <div className="leftContactus">
            <h1>Let's get in touch</h1>
            <p>We're open for any suggestion or just to have a chat</p>
            <div className="contactInfo">
              <h3>
                <i className="fa-solid fa-phone"></i>Phone No
              </h3>
              <p>: 9862170046, 9813522418</p>
            </div>
            <div className="contactInfo">
              <h3>
                <i className="fa-solid fa-envelope"></i>Email
              </h3>
              <p>: info@ebidhya.com</p>
            </div>
            <div className="contactInfo">
              <h3>
                <i className="fa-solid fa-location-dot"></i>Address{" "}
              </h3>
              <p>: Kamanapa-10, Baneshwor, Kathmandu</p>
            </div>
          </div>
          <div className="rightContactus">
            <h1>Get in touch</h1>
            <div className="allInputs">
              <div className="inputs">
                <h2>FULL NAME</h2>
                <input type="text" placeholder="Name" />
              </div>
              <div className="inputs">
                <h2>EMAIL ADDRESS</h2>
                <input type="text" placeholder="Email Adress" />
              </div>
              <div className="inputonly">
                <h2>SUBJECT</h2>
                <input type="text" placeholder="Subject" />
              </div>
              <div className="inputonly">
                <h2>MESSAGE</h2>
                <input type="text" placeholder="Message" />
              </div>
              <button>Send Message</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </div>
  );
}
