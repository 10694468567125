import React from "react";
import Footer from "./Footer";
import GetStarted from "./GetStarted";
import Header from "./Header";
import Services from "./Services";
import Copyright from "./Copyright";
import Tutors from "./Tutors";

export default function Home1() {
  return (
    <div>
      <Header />
      <GetStarted />
      <Services />
      <Tutors />
      <Footer />
      <Copyright />
    </div>
  );
}
